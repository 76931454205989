<template>
   <div
        v-if="!$store.getters['common/isLoading']"
        class="d-flex flex-column"
    >
        <div class="d-flex flex-column flex-sm-row mb-1">
            <div class="d-flex flex-column mb-8 mb-sm-0">
                <h5 class="h5-text mb-2">Запросы на лизинг</h5>
                <p class="subhead-text grey--text"></p>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
        >
            <!--dc templates-->
            <template v-slot:no-data>
                Пока что заявок нет
            </template>
            <template v-slot:item.id="{ item }">
                <a :href="`/${interface}/request/${item.id}`">{{item.id}}</a>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{new Date(item.created_at).toLocaleString()}}
            </template>
            <template v-slot:item.car_brand_id="{ item }">
                {{carBrands.find(brand => brand.id == item.car_brand_id).name}} {{item.car_model.name}}
            </template>
            <template v-slot:item.request_count="{ item }">
                <div class="offer_counter">{{item.offers.length}}</div>
            </template>
            <template v-slot:item.cp_count="{ item }">
                <div class="offer_counter">{{item.offers.filter(offer => offer.approval_probability != 0).length}}</div>
            </template>
            <!--lc templates-->
            <template v-slot:item.leasing_request.car_brand_id="{ item }">
                {{carBrands.find(brand => brand.id == item.leasing_request.car_brand_id).name}} {{item.leasing_request.car_model.name}}
            </template>
            <!--admin templates-->
            <template v-slot:item.user="{ item }">
                {{item.user.first_name}} {{item.user.last_name}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import LeasingRequest from '@/apis/LeasingRequest'
import Offers from '@/apis/Offers'
import axios from 'axios'
import Brands from '@/apis/Brands'
import { Http } from '../../apis/Http'

const headers = {
    dc: [
        {
            text: 'Номер',
            sortable: false,
            value: 'id'
        },
        {
            text: 'Дата запроса',
            sortable: false,
            value: 'created_at'
        },
        {
            text: 'Транспортное средство',
            sortable: false,
            value: 'car_brand_id'
        },
        {
            text: 'Стоимость',
            sortable: false,
            value: 'price'
        },
        {
            text: 'Клиент',
            sortable: false,
            value: 'company_name'
        },
        {
            text: 'Запросов направлено',
            sortable: false,
            value: 'request_count'
        },
        {
            text: 'КП получено ',
            sortable: false,
            value: 'cp_count'
        }
    ],
    admin: [
         {
            text: 'Номер',
            sortable: false,
            value: 'id'
        },
        {
            text: 'Дата запроса',
            sortable: false,
            value: 'created_at'
        },
        {
            text: 'Транспортное средство',
            sortable: false,
            value: 'car_brand_id'
        },
        {
            text: 'Стоимость',
            sortable: false,
            value: 'price'
        },
        {
            text: 'Клиент',
            sortable: false,
            value: 'company_name'
        },
        {
            text: 'Инициатор',
            sortable: false,
            value: 'user'
        },
        {
            text: 'ДЦ',
            sortable: false,
            value: 'user.manager_ds.dealership.name'
        },
        {
            text: 'Запросов направлено',
            sortable: false,
            value: 'request_count'
        },
        {
            text: 'КП получено ',
            sortable: false,
            value: 'cp_count'
        }
    ],
    lc: [
        {
            text: 'Номер',
            sortable: false,
            value: 'id'
        },
        {
            text: 'Дата запроса',
            sortable: false,
            value: 'created_at'
        },
        {
            text: 'Транспортное средство',
            sortable: false,
            value: 'leasing_request.car_brand_id'
        },
        {
            text: 'ДЦ',
            sortable: false,
            value: 'leasing_request.user.manager_ds.dealership.name'
        },
        {
            text: 'Стоимость',
            sortable: false,
            value: 'leasing_request.price'
        },
        {
            text: 'Клиент',
            sortable: false,
            value: 'leasing_request.company_name'
        }
    ]
}

export default {
    data() {
        return {
            interface: this.$route.params.interface ? this.$route.params.interface : 'dc',
            headers: headers[this.$route.params.interface],
            items: [],

            pagination: {
                total: null,
                currentPage: null,
                firstPage: null,
                lastPage: null,
                firstPageUrl: null,
                lastPageUrl: null,
                nextPageUrl: null,
                perPage: null
            },
            perPage: 10,
            carBrands: [],
            carModels: []
        }
    },

    mounted() {
        this.getRequestsData()
        Brands.fetchBrands().then(({ data }) => {
            this.carBrands = data.data
        })
    },

    methods: {
        getRequestsData() {
            switch (this.interface) {
                case 'dc':
                    LeasingRequest.getListByDealer().then(res => {
                        this.items = res.data
                    })
                    break
                case 'lc':
                    Offers.getList().then(res => {
                        this.items = res.data
                    })
                    break
                case 'admin':
                    LeasingRequest.getLeasingRequests().then(res => {
                        this.items = res.data
                    })
                    break
            }
        }
    }
}
</script>

<style scoped>
    .offer_counter {
        background: #EBF9FF;
        color: #3A76F0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        width: 40px;
        height: 38px;
        line-height: 38px;
        border-radius: 7px;
        text-align: center;
    }
</style>
